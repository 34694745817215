<template>
  <div class="hello">
  </div>
</template>

<script>
export default {
  name: 'MyPopup',
  props: {
    msg: String
  }
}
</script>
