<template>
  <h1>About</h1>
  <p>Nationalizing Epics is developing in parallel with National Epics, a collaborative work of c. 83 or 84 chapters, to be published by Oxford University Press (UK). While National Epics suggests a stabilized outcome, a multi-volume book with reference value, Nationalizing Epics evokes the process of getting there, acknowledging that the nature and dynamics of nationalism, and the texts that serve or subvert it, never rest. And while most such large-scale projects develop behind closed doors, Nationalizing Epics aims to share resources and air critical issues along the way.</p> 

<p>National Epics will run from Albania and Algeria to Vietnam and Wales, an alphabetical organization that reproduces the sovereign, Olympian starkness of nationalism. Each National Epics author also contributes to Nationalizing Epics, initially by briefly discussing how a designated territorial space shapes or is shaped by "epical" texts that tell its story to the world. Collectively and cumulatively, we will help augment the emergent disciplinary field known as critical nationalism studies.</p>

<p>Some national epics long predate the nations that come to claim them, exfoliating in multiple and regional forms of telling, with scant regard for later, "national" boundaries. Their adoption, editing, and regularization may marginalize other, contemporaneous narratives; their internal heterogeneity (linguistic, ethnic, and cultural) may be downplayed for strategic, homogenizing, nationalizing purposes. Precarity may be disguised.  National epics are not discovered as such, but are rather adopted, groomed, and cultivated. Some national epics, losing caché and point d'application, come to be supplanted; others, through critical re-readings, are repurposed in ways that outrun the intentions of their first adopters. Any given national epic, never static, is forever gaining or losing cultural and curricular relevance. </p>

<p>The key term epic invokes literary form while carrying western baggage (although, paradoxically, very few "pure" epics grace the western canon). Epic functions as a term of art, standing in for many other terms in other languages. Forms of epicity may vary widely, according to region and the timing of "national" emergence. The Song of Kiều rewrites Chinese pulp fiction into exquisite Vietnamese verse; The Story of Hong Gildong creates Korean mass fiction for the later nineteenth century (while pretending to follow Heo Gyun, 1569-1618, a Chinese master). Jose Rizal published Noli Me Tangere in Germany in 1887, aiming to liberate his native Philippines (where he was shot, or martyred, in 1896); Pramoedya Ananta Toer began his new epic tetralogy for a new nation at Buru Island Prison Camp, Indonesia, in 1973. Each of these Asian works enjoys national epic status; "Hong Gildong" is the Korean equivalent of "John Doe," the placeholder name for every citizen. Many European works, by contrast, now struggle to sustain their sometime epical, nation-making allure. Some, in today's diverse classrooms, have become hard to teach.</p>
 
<p>The turn to nationalism signaled by this project's title might seem surprising, or counter-intuitive. Globalism has become the default mechanism of academic discourse and literary history: global antiquity, global modernity, the global Middle Ages. National Epics seeks not to deny the validity of such approaches, but rather to complement them: for it is evident that within current conditions of warp-speed global connectivity, cultural and political forms of nationalism are ascendant. 5G networks proliferate, but with growing suspicion of the builders. Supply chains seem too long, diseases cross borders, and voters are swayed by protectionist and nationalist narratives. Initiatives in trans-national partnership, treaty-keeping, and conservation have been in retreat. Populism and dictatorship, often racially-inflected, have been on the rise. Vaccination priorities, against the borderless threat of Covid-19, have defaulted to nation-based approaches. By 2040, according to one scenario, the world may regress into "separate silos," a retreat "to subnational ethnic and religious identities" (www.dni.gov/index.php/global-trends-home).  It thus seems timely to undertake a major, collaborative review of the cultural mechanisms of nationalism. This does not represent an alternative to globalism, since the global economy itself exacerbates disparities between regions and nations. It does recognize that the greatest global challenge of our times, climate change, will not be solved nation-by-nation (and it must be solved before 2040). Nationalizing Epics, in laying bare the literary mechanics of nation-making, joins myriad initiatives now playing through the twinned, defining epics of our time: universal human health and planetary survival.</p>
<br><br>

</template>